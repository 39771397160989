.bm-burger-button {
	position: fixed;
	width: 20px;
	height: 20px;
	left: 20px;
	top: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
	background: #bdc3c7;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
	background: #343a40;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
	height: 24px;
	width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
	background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
	position: fixed;
	height: 100%;
}

/* General sidebar styles */
.bm-menu {
	background: #373a47;
	padding: 2.5em 1.5em 0;
	font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
	fill: #343a40;
}

/* Wrapper for item list */
.bm-item-list {
	color: #b8b7ad;
	padding: 0.8em;
}

/* Individual item */
.bm-item {
	display: inline-block;

	color: #d1d1d1;
	margin-bottom: 10px;
	text-align: left;
	text-decoration: none;
	transition: color 0.2s;
}

/* Styling of overlay */
.bm-overlay {
	background-color: #343a40;
}

.bm-item:hover {
	color: #ffffff;
}
